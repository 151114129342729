// @ts-nocheck
import { Controller } from 'stimulus'
import Rails from "@rails/ujs";
import debounce from 'lodash.debounce'

export default class extends Controller {
  static targets = [
    "keywordInput",
    'category',
    'panelType',
    'searchTitleCategory',
    'searchTitlePanelType',
    'searchResult',
    'readMoreButton'
  ]
  static values = {
    category: String,
    panelType: String,
    categoryId: Number,
    panelTypeId: Number,
    prevSearchParams: String,
    categories: Array,
    blogs: Array,
    currentPage: Number,
    nextPage: Number,
    isSearchCompleted: Boolean,
  }

  initialize() {
    this.prevSearchParamsValue = ''
    this.resetPageValues()

    this.onSubmit()
  }

  connect() {
    this.keywordInputTarget.addEventListener('keydown', debounce(() => {
      this.onSubmit()
    }, 1000))
    this.keywordInputTarget.addEventListener('blur', () => {
      this.onSubmit()
    })
  }

  generateCategoryElements() {
    const listItems = this.categoriesValue.map(category => {
      const isActive = this.categoryIdValue === category.id
      const li = document.createElement( 'li' );
      li.className = `${isActive &&'active'} c-category__tab__item`
      li.dataset['action'] = 'click->category-search#onChangeCategory'
      li.dataset['category_id'] = category.id
      li.textContent = category.name

      if (isActive) { this.categoryValue = category.name }

      return li
    })

    this.categoryTarget.replaceChildren(...listItems)
    this.categoryValueChanged()
  }

  generatePanelTypeElements() {
    const activeCategory = this.categoriesValue.find( category => this.categoryIdValue === category.id );
    const listItems = activeCategory.panel_types.map( panelType => {
      const isActive = this.panelTypeIdValue === panelType.id
      const li = document.createElement( 'li' )
      li.className = `${isActive && 'active'} c-panel-type__tab__item`
      li.dataset['action'] = 'click->category-search#onChangePanelType'
      li.dataset['panel_type_id'] = panelType.id
      li.textContent = panelType.name

      if (isActive) { this.panelTypeValue = panelType.name }

      return li
    })

    this.panelTypeTarget.replaceChildren(...listItems)
    this.panelTypeValueChanged()
  }

  generateBlogsElements() {
    const listItems = this.blogsValue.map( blog => {
      const aTag = document.createElement('a')
      aTag.className = 'keyword'
      aTag.href = blog.url
      aTag.textContent = blog.panel_name

      return aTag
    })

    this.searchResultTarget.replaceChildren(...listItems)
  }

  onChangeCategory(e) {
    [ ...e.currentTarget.parentElement.children ].find( el => el.className.includes( 'c-category__tab__item' ) && el.className.includes( 'active' ) ).classList.remove( 'active' )
    this.categoryTarget.value = e.currentTarget.textContent
    this.categoryValue = e.currentTarget.textContent
    this.categoryIdValue = Number(e.currentTarget.dataset.category_id)
    e.currentTarget.classList.add('active')

    this.resetPageValues()
    this.onSubmit()
  }

  onChangePanelType(e) {
    [...e.currentTarget.parentElement.children].find(el => el.className.includes('c-panel-type__tab__item') && el.className.includes('active')).classList.remove('active')
    this.panelTypeTarget.value = e.currentTarget.textContent
    this.panelTypeValue = e.currentTarget.textContent
    this.panelTypeIdValue = Number(e.currentTarget.dataset.panel_type_id)
    e.currentTarget.classList.add('active')

    this.resetPageValues()
    this.onSubmit()
  }

  categoryValueChanged() { this.searchTitleCategoryTarget.textContent = this.categoryValue }

  panelTypeValueChanged() {
    this.searchTitlePanelTypeTarget.textContent = this.panelTypeValue
  }

  isSearchCompletedValueChanged() {
    if (this.isSearchCompletedValue) {
      this.readMoreButtonTarget.classList.add('is-hide')
    } else {
      this.readMoreButtonTarget.classList.remove('is-hide')
    }
  }

  resetPageValues() {
    this.currentPageValue = 1
    this.nextPageValue = 0
  }

  generateSearchParams() {
    const searchParams = new URLSearchParams()
    if (this.categoryIdValue) searchParams.append('category_id', this.categoryIdValue)
    if (this.panelTypeIdValue) searchParams.append('panel_type_id', this.panelTypeIdValue)
    searchParams.append('q', this.keywordInputTarget.value)

    // NOTE: nextPageValueが0の時はresetPageValuesによって初期化した後のためこれの場合1ページめをリクエストす る
    if (this.nextPageValue === 0) {
      searchParams.append('page', 1)
    } else {
      searchParams.append('page', this.nextPageValue)
    }

    return searchParams.toString()
  }

  onSubmit() {
    const searchParams = this.generateSearchParams()
    if (this.prevSearchParamsValue === searchParams) return

    Rails.ajax({
      type: 'GET',
      url: `/categories/search?${searchParams}`,
      success: ( data, _status, _xhr ) => {
        this.categoriesValue = data.categories
        if (this.categoryIdValue !== data.active_category.id) this.categoryIdValue = data.active_category.id
        if (this.panelTypeIdValue !== data.active_panel_type.id) this.panelTypeIdValue = data.active_panel_type.id
        if (data.pagination.should_refresh) {
          this.blogsValue = data.blogs
        } else {
          this.blogsValue = this.blogsValue.concat(data.blogs)
        }

        this.currentPageValue = data.pagination.current
        const nextPage = data.pagination.next || -1
        this.nextPageValue = nextPage

        this.generateCategoryElements()
        this.generatePanelTypeElements()
        this.generateBlogsElements()
        this.prevSearchParamsValue = searchParams
        this.isSearchCompletedValue = (nextPage === -1)
      }
    })
  }
}
