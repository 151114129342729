(function () {
  $(document).on("turbolinks:load", function () {
    if (!!$(".js-file-field").length) {

      $('.js-file-field').change(function(e) {
        let file = e.target.files[0]
        if(file.type.indexOf("image") < 0){
          alert('画像を選択してください');
          $(this).val('');
          return false;
        }
        let reader = new FileReader();
        let filename = file.name;

        reader.onload = function (e) {
         $('.js-upload_avatar').attr({
            src: e.target.result,
            alt: filename
          });
        }
        reader.readAsDataURL(file);
      });
    }
  });
})();
