(function () {
  $(document).on("turbolinks:load", function () {
    if (!!$(".js-l-detail").length) {
      // 補足説明がリストを含む場合のスタイル調整
      $("div.l-detail__addition:has(ul, ol)").addClass("list_parent");
      // アフィリエイト引用がリストを含む場合のスタイル調整
      $("div.affiliate_blockquote:has(ul, ol)").addClass("list_parent");
    }
  });
})();
