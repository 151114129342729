(function () {
  $(document).on("turbolinks:load", () => {
    const container = document.querySelector('.js-l-detail')
    if (!!container) {
      const IGNORE_PREVIEW_IMG_ALTS = [
        'ぴっかちゃん'
      ];

      const images = [...container.querySelectorAll('img')].filter(img => !img.classList.contains('js-image_closeup_exclude'));
      const modalContainer = document.querySelector('.modal-container');
      const modalImage = document.querySelector('.modal-image');

      [...images].forEach(img => {
        if (IGNORE_PREVIEW_IMG_ALTS.includes(img.alt) || img.className == 'modal-image') {
          return
        }

        img.addEventListener('click', (event) => {
          modalImage.src = event.currentTarget.src;
          modalImage.alt = '画像ファイルのプレビューが表示されています'
          $(modalContainer).show()
        })
      });

      modalContainer.addEventListener('click', () => {
        $(modalContainer).hide()
      })
    }
  })
})();
