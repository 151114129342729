(function () {
  $(document).on("turbolinks:load", function () {
    const target = document.querySelector('.js-advertize-hide-button')
    if (target) {
      target.addEventListener("click", ev => {
        ev.currentTarget.closest('.advertize').style.display = 'none'
        $(".p-button__wakatta").removeClass("has-advertize");
      })
    }
  });
})();
