import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {

  static values = { apiPath: String }

  initialize() {
    this.apiPath = window.location.pathname
  }

  onClick(e) {
    const { advertizeId } = e.currentTarget.dataset
    this.submit(advertizeId)
  }

  submit(advertizeId) {
    const payload = new FormData()
    payload.append('advertize_id', advertizeId)

    Rails.ajax({
      type: 'POST',
      url: `${this.apiPath}/advertizes`,
      dataType: 'json',
      data: payload,
    })
  }
}
