(function () {
  $(document).on("turbolinks:load", function () {
    // レスポンシブで切り替える
    // Laptop小サイズ以上の時だけ発火
    if (window.matchMedia("screen and (min-width:769px)").matches) {
      // min-width 以上になったら（Laptop小サイズ以上になったら）
      //
      // 記事詳細
      //
      // 記事詳細の見出しにhoverでツールチップ表示
      $(".js-header-link").on({ // 見出しのhoverエリア
        "mouseenter": function (e) {
          $(this).find(".p-tooltip__link").addClass("is-active"); // ツールチップにクラス付与
        },
        "mouseleave": function (e) {
          $(this).find(".p-tooltip__link").removeClass("is-active");
        },
      });

      // リンクアイコンをクリックした時の処理
      if (!!$(".c-tooltip__link").length) {
        document.querySelectorAll(".c-tooltip__link").forEach((el) => {
          el.addEventListener("click", (e) => {
            e.preventDefault();
            const nextPath = $(e.currentTarget)
              .find("a")
              .attr("href");
            const copyIdPath = location.origin + location.pathname + nextPath;
            const visibleTextArea = document.createElement("textarea");
            visibleTextArea.className = "js-will-delete";
            visibleTextArea.textContent = copyIdPath;
            visibleTextArea.style.position = "fixed";
            visibleTextArea.style.left = "-100%";
            document.body.appendChild(visibleTextArea);
            visibleTextArea.select();
            document.execCommand("copy");
            $(e.currentTarget)
              .find(".c-tooltip__head-tip")
              .fadeIn("fast")
              .delay(1000)
              .fadeOut();
            $(".js-will-delete").remove();
          });
        });
      }

      //
      // マイページ
      //
      // 記事詳細の見出しにhoverでツールチップ表示

      $(document).on({
        mouseenter: function() {
          $(this).find(".p-tooltip__trash").addClass("is-active"); // ツールチップにクラス付与
        },

        mouseleave: function() {
          $(this).find(".p-tooltip__trash").removeClass("is-active");
        }
      }, '.p-article__list--mypage');

      $(document).on({
        mouseenter: function() {
          $(this).find(".c-tooltip__head-tip--mypage").addClass("is-active"); // ツールチップにクラス付与
        },

        mouseleave: function() {
          $(this).find(".c-tooltip__head-tip--mypage").removeClass(
            "is-active")
        }
      }, '.c-tooltip__trash-icon');
      // ツールチップのリンクアイコンをhoverした時に吹き出しを出す
    }
  });
})();
