import ClipBoard from "stimulus-clipboard"

export default class extends ClipBoard {
  static values = {
    text: String,
    successContent: {
      type: String,
      default: 'copied!'
    },
    successDuration: {
      type: Number,
      default: 2000
    }
  }

  static targets = ['effect']

  connect() {
    super.connect()
  }

  copy() {
    navigator.clipboard.writeText(this.textValue.trim())

    this.copied()
  }

  copied() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.effectTarget.innerText = this.successContentValue

    this.timeout = setTimeout(() => {
      this.effectTarget.innerText = ""
    }, this.successDurationValue)
  }
}

