(function () {
  $(document).on("turbolinks:load", function () {
    // スクロールでわかったボタンを出し入れする挙動
    var startPos = 0, winScrollTop = 0;

    if (!!document.querySelector('.advertize') && $(window).width() < 768) {
      $(".p-button__wakatta").addClass("has-advertize");
    }

    $(window).scroll(function () {
      winScrollTop = $(this).scrollTop();

      if (window.matchMedia("screen and (max-width: 1150px)").matches) {
        if (winScrollTop >= startPos) {
          $(".p-button__wakatta").addClass("is-hide");
        } else {
          $(".p-button__wakatta").removeClass("is-hide");
        }
        startPos = winScrollTop;
      } else if (window.matchMedia("screen and (min-width:1151px)").matches) {
        $(".p-button__wakatta").removeClass("is-hide");
      }
    });

    $('.js-wakatta-button-need-to-login').on('click', () => {
      alert('分かった！機能は、ログインすることで利用できます')
    })
  });
})();
