(function () {
  $(document).on("turbolinks:load", () => {
    const iframeContainer = document.querySelector('.js-iframe-container');
    if (!iframeContainer) { return }

    iframeContainer.addEventListener("click", () => iframeContainer.style.display = "none")

    const bg = iframeContainer.querySelector('.bg')
    bg.addEventListener('click', e => e.stopPropagation())

    const closeButton = iframeContainer.querySelector('.js-close')
    closeButton.addEventListener("click", () => iframeContainer.style.display = "none");

    [...document.querySelectorAll('.js-iframe-link')].forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const link = e.currentTarget.href
        let iframeElement = iframeContainer.querySelector('iframe')
        iframeElement.src = link
        iframeContainer.style.display = 'flex'
        iframeContainer.classList.add('active')
      })

    });
  })
})();
