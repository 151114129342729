(function () {
  $(document).on("turbolinks:load", function () {
    $(".js-sidebar_click_counts_by_image").on("click", function () {
      const data = { sidebar_click_count: { content: 'office_chair', url: location.pathname, medium: '画像'} };
      post_ajax(data)
    });

    $(".js-sidebar_click_counts_school_by_image").on("click", function () {
      const data = { sidebar_click_count: { content: 'school_list', url: location.pathname, medium: '画像'} };
      post_ajax(data)
    });

    $(".js-sidebar_click_counts_by_button").on("click", function () {
      const data = { sidebar_click_count: { content: 'office_chair', url: location.pathname, medium: 'オフィスチェアボタン'} };
      post_ajax(data)
    });

    $(".js-sidebar_click_counts_by_text").on("click", function () {
      const data = { sidebar_click_count: { content: 'office_chair', url: location.pathname, medium: 'オフィスチェアテキスト'} };
      post_ajax(data)
    });

    $(".js-h2_counts_by_button").on("click", function () {
      const data = { sidebar_click_count: { content: '未経験者求人', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_counts_zenn_by_text_first").on("click", function () {
      const data = { sidebar_click_count: { content: 'zennのテキスト1', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_counts_zenn_by_text_second").on("click", function () {
      const data = { sidebar_click_count: { content: 'zennのテキスト2', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_counts_zenn_by_image").on("click", function () {
      const data = { sidebar_click_count: { content: 'zennの画像', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_line_text_first").on("click", function () {
      const data = { sidebar_click_count: { content: 'twitterのテキスト一つ目', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_line_text_second").on("click", function () {
      const data = { sidebar_click_count: { content: 'twitterのテキスト二つ目', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_line_text_second_new_service_pc").on("click", function () {
      const data = { sidebar_click_count: { content: 'PCでのアンケートのボタン', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_line_text_second_new_service_sp").on("click", function () {
      const data = { sidebar_click_count: { content: 'SPでのアンケートのボタン', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_recruit_agency").on("click", function () {
      const data = { sidebar_click_count: { content: '転職相談', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_pikawaka_class_text").on("click", function () {
      const data = { sidebar_click_count: { content: 'テキストリンク', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_website_workshop_text").on("click", function () {
      const data = { sidebar_click_count: { content: 'ワークショップテキストリンク', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-header_zenn_curriculum").on("click", function () {
      const data = { sidebar_click_count: { content: 'ヘッダー（zenn）', url: location.pathname, medium: 'header'} };
      post_ajax(data)
    });

    $(".js-header_workshop").on("click", function () {
      const data = { sidebar_click_count: { content: 'ヘッダー（workshop）', url: location.pathname, medium: 'header'} };
      post_ajax(data)
    });

    $(".js-header_school").on("click", function () {
      const data = { sidebar_click_count: { content: 'ヘッダー（スクール）', url: location.pathname, medium: 'header'} };
      post_ajax(data)
    });

    $(".js-header_lp").on("click", function () {
      const data = { sidebar_click_count: { content: 'lp', url: location.pathname, medium: 'header'} };
      post_ajax(data)
    });

    $(".js-sidebar_new_service_click_counts_by_image").on("click", function () {
      const data = { sidebar_click_count: { content: '新サービスアンケートの画像', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-sidebar_new_service_click_counts_by_button").on("click", function () {
      const data = { sidebar_click_count: { content: '新サービスアンケートのボタン', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-sidebar_workshop_by_image").on("click", function () {
      const data = { sidebar_click_count: { content: 'ワークショップの画像', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-sidebar_workshop_by_button").on("click", function () {
      const data = { sidebar_click_count: { content: 'ワークショップのボタン', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-paid_zenn_curriculum").on("click", function () {
      const data = { sidebar_click_count: { content: '有料画面（zenn）', url: location.pathname, medium: 'paid_view'} };
      post_ajax(data)
    });

    $(".js-paid_pikawaka_zenn_curriculum").on("click", function () {
      const data = { sidebar_click_count: { content: '有料画面（pikawaka_zenn）', url: location.pathname, medium: 'paid_view'} };
      post_ajax(data)
    });

    $(".js-paid_pikawaka_zenn_curriculum_second").on("click", function () {
      const data = { sidebar_click_count: { content: '有料画面（pikawaka_zenn_second）', url: location.pathname, medium: 'paid_view'} };
      post_ajax(data)
    });

    $(".js-paid_upgrade_button").on("click", function () {
      const data = { sidebar_click_count: { content: 'アップグレードボタン', url: location.pathname, medium: 'paid_button'} };
      post_ajax(data)
    });

    $(".js-h2_pikawaka_class_text_link").on("click", function () {
      const data = { sidebar_click_count: { content: 'ピカワカクラステキストリンク', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_pikawaka_class_image").on("click", function () {
      const data = { sidebar_click_count: { content: 'ピカワカクラス画像', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-h2_pikawaka_manager").on("click", function () {
      const data = { sidebar_click_count: { content: 'ピカワカクラスの担当講師ページ', url: location.pathname, medium: 'h2'} };
      post_ajax(data)
    });

    $(".js-profile_pikawaka_class_text_link").on("click", function () {
      const data = { sidebar_click_count: { content: 'プロフィールページからのLPリンク', url: location.pathname, medium: 'prifle'} };
      post_ajax(data)
    });

    $(".js-sidebar_click_counts_by_lp_banner_600_500_A").on("click", function () {
      const data = { sidebar_click_count: { content: '複数人の受講生が出ているlpバナーの画像', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-sidebar_click_counts_by_lp_banner_600_500_B").on("click", function () {
      const data = { sidebar_click_count: { content: '1人の受講生が出ているlpバナーの画像', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-sidebar_click_counts_by_website_workshop").on("click", function () {
      const data = { sidebar_click_count: { content: 'ワークショップバナーの画像', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-h2_school_to_janreco").on("click", function () {
      const data = { sidebar_click_count: { content: 'スクール紹介文からJanreco', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });

    $(".js-h2_school_otoiawase_to_manager").on("click", function () {
      const data = { sidebar_click_count: { content: 'スクール紹介文からお問い合わせ', url: location.pathname, medium: 'sidebar'} };
      post_ajax(data)
    });
  });

  function post_ajax(data) {
    $.ajax({
      type: 'POST',
      url: '/sidebar_click_count',
      data: data,
      beforeSend: function(xhr) {
        xhr.setRequestHeader(
          'X-CSRF-Token',
          $('meta[name=csrf-token]').attr('content')
        );
      },
      dataType: 'json'
    })
  }
})();
