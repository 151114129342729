global.$ = global.jQuery = require("jquery");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("./channels");
require("./common/contents");
require('./common/specific_child_element');
require("./public/flickity");
require("./public/top-slider");
require("./public/slide-menu");
require("./public/modal");
require("./public/tooltip");
require("./public/wakatta-button");
require("./public/image_uploader");
require("./public/sidebar");
require("./public/sidebar_click_counts");
require("./public/advertize_hide-button");
require("./public/image_preview")
require("./public/iframe_viewer")
require("./common/lister");
require("./controllers/index")
require("./common/blog_link");

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
