(function () {
  $(document).on("turbolinks:load", function () {
    if (!!$(".js-blog_link").length) {
      const matches = document.querySelectorAll(".js-blog_link");
      matches.forEach(function (blog_link, i) {
        const url = $(blog_link).attr('href')
        $.ajax({
          type: 'GET',
          url: url,
          beforeSend: xhr => {
            xhr.setRequestHeader(
              'X-CSRF-Token',
              $('meta[name=csrf-token]').attr('content')
            );
          },
          dataType: 'json'
        })
        .done(data => {
          const title = data.title
          const image = data.image
          const description = data.description
          const url = data.url
          let html = `
                      <div class="p-blog_link_area">
                        <div class="p-blog_link_area__main">
                          <div class="p-blog_link_area__main__left">
                            <a href="${url}"><img src="${image}" alt="ブログリンクの画像">
                            </a>
                          </div>
                          <div class="p-blog_link_area__main__right">
                            <a class="p-blog_link_area__main__right__title" href="${url}">${title}</a>
                            <p class="p-blog_link_area__main__right__description js-blog_link_description">${description}</p>
                            <a class="p-blog_link_area__main__right__link c-button--read-article" href="${url}">記事の続きを見る</a>
                          </div>
                        </div>
                      </div>
                      `
          $(blog_link).replaceWith(html);
        })
        .fail((data) => alert('通信に失敗しました。もう一度試してみてください。'))
      })
    }
  });
})();
