(function () {
  $(document).on("turbolinks:load", function () {
    if (!!$("#carousel").length) {
      //
      // トップページのスライダーの処理
      //
      // HTML記述をロードした時の状態でセーブ
      var defaultHTML;
      function DefaultSave() {
        defaultHTML = document.getElementById("carousel").innerHTML;
      }
      DefaultSave();
      // レスポンシブで切り替える
      function handleWindowResize() {
        //   if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
        //     // モバイルだったら
        //     // TODO
        //     console.log('mobile')
        //     return
        // }
        if (window.matchMedia("screen and (max-width: 768px)").matches) {
          // max-width 以下になったら（SPになったら）
          $(".l-article__thumbs").addClass("main-carousel");
          $(".l-article__thumbs > a").addClass("carousel-cell");
          $(".main-carousel").flickity({
            wrapAround: true,
          });
        } else if (
          window.matchMedia("screen and (min-width:769px)").matches
        ) {
          // min-width 以上になったら（タブレット以上になったら）
          // HTML記述を初期状態に戻す
          function HTMLRestore() {
            document.getElementById("carousel").innerHTML = defaultHTML;
          }
          HTMLRestore();
        }
      }
      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);
    }
  });
})();
