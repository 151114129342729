(function () {
  $(document).on("turbolinks:load", function () {
    if (!!$("#side-category").length) {
      // HTML記述をロードした時の状態でセーブ
      var defaultHTML;
      function DefaultSave() {
        defaultHTML = document.getElementById("side-category").innerHTML;
      }
      DefaultSave();
      // レスポンシブで切り替える
      function handleWindowResize() {
        //
        // Laptop小サイズ以下で使うスライドメニュー
        //
        // Laptop小サイズ以下の時だけ発火
        if (window.matchMedia("screen and (max-width: 1023px)").matches) {

          $(".c-sidebar__accordion-menu").on({
            "click": function () {
              var $checkedMenu = $(this).parents(".p-sidebar__category").find(".p-sidebar__toggle");

              if ( $(this).prop('checked') == true ) {
                $checkedMenu.css({
                  "height" : "100%",
                  "opacity" : "1",
                  "visibility" : "visible"
                });
              } else {
                $checkedMenu.css({
                  "height" : "0",
                  "opacity" : "0",
                  "visibility" : "hidden"
                });
              }
            }
          });
        } else if (window.matchMedia("screen and (min-width:1024px)").matches) {
          // min-width 以上になったら（タブレット以上になったら）
          // HTML記述を初期状態に戻す
          function HTMLRestore() {
            document.getElementById("side-category").innerHTML = defaultHTML;
          }
          HTMLRestore();
        }
      }
      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);
    }
  });
})();
