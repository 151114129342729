(function () {
  $(document).on("turbolinks:load", function () {
    if (!!$(".accordion").length && !!$(".c-button--answer").length) {
      $(".accordion dd").hide();
      $(".c-button--answer").on("click", function () {
        if (
          $(this)
            .parent()
            .find("dd")
            .css("display") == "none"
        ) {
          $(this)
            .parent()
            .find("dd")
            .show();
        } else {
          $(this)
            .parent()
            .find("dd")
            .hide();
        }
      });
    }
  });
})();
