(function () {
  $(document).on("turbolinks:load", function () {
    //
    // モーダル
    //
    // 表示非表示の挙動
    var $window = $(window),
      $html = $("html"),
      $body = $("body"),
      $overlay = $(".l-modal"),
      $overlay_sign_up = $(".l-modal--sign-up"),
      $overlay_sign_in = $(".l-modal--sign-in"),
      $addCss = {
        "display": "-webkit-flex",
        "display": "-ms-flexbox",
        "display": "flex",
        "-webkit-align-items": "center",
        "-ms-flex-align": "center",
        "align-items": "center",
      },
      scrollbar_width = window.innerWidth - document.body.scrollWidth,
      touch_start_y;

    $window.on("touchstart", function (event) {
      touch_start_y = event.originalEvent.changedTouches[0].screenY;
    });

    $(".sign-up, .sign-in").on("click", function () {
      $window.on("touchmove.noscroll", function (event) {
        var overlay = $overlay[0],
          current_y = event.originalEvent.changedTouches[0].screenY,
          height = $overlay.outerHeight(),
          is_top = touch_start_y <= current_y && overlay.scrollTop === 0,
          is_bottom = touch_start_y >= current_y &&
            overlay.scrollHeight - overlay.scrollTop === height;

        if (is_top || is_bottom) {
          event.preventDefault();
        }
      });

      $("html, body").css("overflow", "hidden");

      if (scrollbar_width) {
        $html.css("padding-right", scrollbar_width);
      }

      if ($(this).hasClass("sign-up")) {
        $overlay_sign_up.fadeIn(300);
      } else {
        $overlay_sign_in.fadeIn(300);
      }
    });

    var closeModal = function () {
      $body.removeAttr("style");
      $window.off("touchmove.noscroll");

      $overlay.animate(
        {
          opacity: 0,
        },
        300,
        function () {
          $overlay.scrollTop(0).hide().removeAttr("style");
          $html.removeAttr("style");
        },
      );
    };

    $overlay.on("click", function (event) {
      if (!$(event.target).closest(".l-modal__inner").length) {
        closeModal();
      }
    });

    // モーダルの新規登録とログインの切り替え
    $(".switch-to-sign-in").on("click", function () {
      $(".l-modal--sign-up").css("display", "none");
      $(".l-modal--sign-in").css("display", "block");
    });
    $(".switch-to-sign-up").on("click", function () {
      $(".l-modal--sign-in").css("display", "none");
      $(".l-modal--sign-up").css("display", "block");
    });
  });
})();
